import React, { useRef, useEffect } from 'react';

const Starfield = () => {
  const canvasRef = useRef(null);
  const numStars = 300;
  let stars = [];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Initialize stars
    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * width,
        y: Math.random() * height,
        radius: Math.random() * 1.5,
        vx: Math.random() * 0.2 - 0.05,
        vy: Math.random() * 0.2 - 0.05
      });
    }

    // Update and draw stars
    const updateStars = () => {
      ctx.clearRect(0, 0, width, height);
      stars.forEach(star => {
        star.x += star.vx;
        star.y += star.vy;
        if (star.x < 0 || star.x > width || star.y < 0 || star.y > height) {
          star.x = Math.random() * width;
          star.y = Math.random() * height;
        }
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(000, 000, 000, 0.2)';
        ctx.fill();
      });
      requestAnimationFrame(updateStars);
    };

    updateStars();

    return () => cancelAnimationFrame(updateStars);
  }, []);

  return <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />;
};

export default Starfield;
