import React from 'react';
import Starfield from "./Starfield/Starfield";
import './App.css';

function App() {
  return (
    <div className="App">

      
      <div className="CenterPanel">
        <img src="/orb_logo.svg" alt="Orb Logo" />

        <p>Orbiter is a Dublin-based product design and development group with a focus on Climate Action.</p>
        <p>Led by <a href="https://www.linkedin.com/in/uxbrian/" target="_blank">Brian Rogers</a>, we work with newspapers, non-profits, public bodies and individials to bring industry design and engineering expertise to problems of public good.</p>
        <p>This month, we're working with Dublin Inqurier <a href="https://www.collisiontracker.ie/" target="_blank">to understand and improve active travel safety in Ireland.</a></p>
        <p><a href="https://wa.me/353851031703?text=Hi%20Orbiter" target="_blank">Say hello</a> 👋</p>
        
      </div>

      <Starfield/>
      
    </div>
  );
}

export default App;

